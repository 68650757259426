import { makeStyles } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'
import { RADIUS } from 'common/enums/radius'
import { SHADOW } from 'common/enums/shadow'

export const useStyles = makeStyles(() => {
  return {
    accordionWrapper: {
      margin: '15px auto',
    },
    textWrapper: {
      margin: '1.5rem 0',
      textAlign: 'center',
    },
    title: {
      marginBottom: '1rem',
    },
    buttonWrapper: {
      position: 'absolute',
      bottom: 30,
      left: '5%',
      width: '90%',
      display: 'flex',
      justifyContent: 'center',
    },
    previewWrapper: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'white',
      zIndex: 999,
    },
    previewTopMargin: {
      marginTop: '70px',
    },
    actionsContainer: {
      margin: '112px auto 105px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    eldarContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100px',
      overflow: 'hidden',
      backgroundColor: COLORS.WHITE,
      borderRadius: RADIUS.BOX_RADIUS,
      boxShadow: SHADOW.SHADOW_BOX,
    },
    eldarLogoContainer: {
      padding: '12px',
      width: '120px',
    },
    eldarLogo: {
      width: '100%',
    },
    eldarTextContainer: {
      display: 'flex',
      height: '100%',
      width: '100%',
      paddingInlineStart: '16px',
      paddingInlineEnd: '20px',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#74E37A66',
    },
    leadConfirmation: {
      zIndex: 9999,
      position: 'fixed',
      background: 'white',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100vw',
      height: '100vh',
      opacity: 1,
      transition: 'opacity 0.3s',
    },
    leadConfirmationHide: {
      opacity: 0,
      pointerEvents: 'none',
    },
  }
})
